import React, { Component } from 'react';
import { Query } from 'react-apollo';
import ProductList from './ProductList';
import CategoryProductsQuery from './CategoryProductsQuery.gql';

class CategoryProducts extends Component {
  render() {
    const cat_id = this.props.id;
    const slick = this.props.slick;

    return cat_id ? (
      <Query query={CategoryProductsQuery} variables={{ cat_id }}>
        {({ data, loading }) => {
          if (loading) return '';
          return data &&
            data.category &&
            data.category.products.result.length ? (
            <ProductList
              products={data.category.products.result}
              slick={slick}
            />
          ) : null;
        }}
      </Query>
    ) : null;
  }
}

export default CategoryProducts;
