import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image';
import { Price } from '../Price';
import Badges from '@jetshop/ui/ProductList/Badges';
import { Link } from 'react-router-dom';
import AddFavoriteButton from '../FavoriteList/AddFavoriteButton';
import Slider from 'react-slick';
import { ReactComponent as SliderArrowSVG } from '../../svg/SliderArrow.svg';
import 'slick-carousel/slick/slick.css';

const ImageBg = styled('div')`
  //height: 362px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin: 0;
  background-color: #fff;
  padding-top: 130%;

  ${({ theme }) => theme.below.md} {
    height: auto;
  }
`;

export const ProductSection = styled(Link)`
  width: 100%;
  margin-right: ${({ theme }) => theme.space[2]};
  margin-left: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[3]};
  position: relative;

  ${({ theme }) => theme.above.md} {
    width: ${({ theme }) =>
      `calc(25% - ${theme.space[2]} - ${theme.space[2]})`};
  }

  ${({ theme }) => theme.below.md} {
    width: ${({ theme }) => `calc(50% - ${theme.space[3]})`};
    margin: 0 ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[4]};
    margin-bottom: ${({ theme }) => theme.space[2]};
    padding: 0;
  }

  .product-name {
    padding-top: ${({ theme }) => theme.space[2]};
    & > h4 {
      font-family: ${({ theme }) => theme.fontHeading};
      line-height: 1.4;

      ${({ theme }) => theme.below.md} {
        font-size: 1rem;
      }
    }
    &__sub-title {
      display: block;
      color: ${({ theme }) => theme.colors.greyDark};
      text-transform: uppercase;
      font-size: 0.85rem;
      line-height: 0.75rem;
      font-weight: 500;
      padding: ${({ theme }) => theme.space[1]} 0;
    }
  }

  ${Price.Wrapper} {
    display: flex;

    div {
      margin-right: 8px;
    }
  }

  ${Price.Old} {
    color: #aaa;
  }

  ${Price.New} {
    color: #e34040;
  }
`;

const Container = styled('div')`
  width: 100%;

  & > .slick-slider {
    //padding: 0 ${({ theme }) => parseInt(theme.sizes.gap, 10)}px;
    //margin: 0 -${({ theme }) => parseInt(theme.sizes.gap, 10)}px;
    ${({ theme }) => theme.below.md} {
      padding-bottom: 10px;
    }
    
    
    .slick-next, .slick-prev {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: flex;
      width: 40px;
      height: 40px;
      padding: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;
      border: none;
      border-radius: 100%;
      background: rgba(255,255,255,0.7);
      justify-content: center;
      align-items: center;
      box-shadow: 1px 1px 3px rgba(0,0,0, 0.1); 
      
     &[style*='block'] {
        display: flex !important;
      
     }
      
    }
    
    .slick-prev, .slick-next {
      top: 38%;
      z-index: 2;
      &:before {
        display: none;
      }
    }   
    
    
    & > .slick-prev {
      //left: -10px;
      left: 20px;
    }
    & > .slick-next {
      //right: -10px;
      right: 25px;
    }
  }

  .slick-track {
    //max-height: 540px;
    overflow: hidden;
    ${({ theme }) => theme.below.md} {
      // max-height: 480px;
      // max-height: 300px;
      // margin-bottom: 20px;
    }
  }
  .slick-list {
    //margin: 0 -${({ theme }) => theme.space[2]};
  }
  .slick-slide {
    //margin: 0 ${({ theme }) => theme.space[2]};
    ${({ theme }) => theme.above.md} {
      //width: 275px !important;
      //width: 18.5vw !important;
      //max-width: 270.5px;
    }
    ${({ theme }) => theme.below.md} {
      //width: calc(50vw - 18px) !important;
      //margin: 0 ${({ theme }) => theme.space[1]};
    }

    img {
    //  display: none;

    }

    & > div {
    
    
      padding: 0 ${({ theme }) => parseInt(theme.sizes.gap)}px;
    
    > a {
      margin-left: 0;
      margin-right: 0;
    }
    }
  }


  .slick-dots {
  
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

  
    ${({ theme }) => theme.below.md} {
      bottom: -15px;
    }
    & > li {
      width: 0.95rem;
      position: relative;
      display: inline-block;
      margin: 0 5px;
      padding: 0;
    height: 20px;
    cursor: pointer;
      
      & > button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.black};
        font-size: 0;
        line-height: 0;
        display: block;
        padding: 5px;
        color: transparent;
        outline: none;
        background: transparent;

        &:before {
          opacity: 0 !important;
        }
      }
      &.slick-active {
        & > button {
          background: ${({ theme }) => theme.colors.black};
        }
      }
    }
  }
`;

const NextArrow = styled(SliderArrowSVG)`
  width: 24px;
  height: 20px;
  &:before {
    display: none;
  }
`;

const PrevArrow = styled(SliderArrowSVG)`
  width: 24px;
  height: 20px;
  transform: rotate(180deg) !important;
  &:before {
    display: none;
  }
`;

const BadgeWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;

  ${({ theme }) => theme.below.sm} {
    div > div > img {
      max-width: 50px;
    }
  }
`;

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

const ProductList = ({ products, slick, categoryPath }) => {
  var settings = {
    arrows: true,
    nextArrow: (
      <SlickButtonFix>
        <NextArrow />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <PrevArrow />
      </SlickButtonFix>
    ),
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: '0px',
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false
          //centerPadding: '55px',
          //centerMode: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false
          //centerPadding: '55px',
          //centerMode: true
        }
      }
    ]
  };

  // <Image
  //   src={product.images[0].sizes[1].url}
  //   cover
  //   aspect="3:4"
  //   sizes={[1 / 5]}
  //   alt={product.images[0].alt}
  // >
  //   <AddFavoriteButton product={product} productID={product.id} />
  // </Image>

  if (products && products.length) {
    // console.log(products);
    if (products.length > 4 && slick === true) {
      return (
        <Container>
          <Slider {...settings}>
            {products.map((product, i) => (
              <ProductSection
                key={i}
                to={
                  categoryPath && product.primaryRoute
                    ? categoryPath.path + '/' + product.primaryRoute.slug
                    : product.primaryRoute
                    ? product.primaryRoute.path
                    : '/'
                }
              >
                <div className="product-image">
                  {product.images[0] && product.images[0].url && (
                    <ImageBg
                      style={{
                        backgroundImage: `url(${product.images[0].url.replace(
                          /\/original/i,
                          '/small'
                        )})`
                      }}
                    >
                      {product.badges && product.badges.length > 0 && (
                        <BadgeWrapper>
                          <Badges badges={product.badges} />
                        </BadgeWrapper>
                      )}
                      <AddFavoriteButton
                        product={product}
                        productID={product.id}
                      />
                    </ImageBg>
                  )}
                </div>

                <div className="product-name">
                  <h4>{product.name}</h4>
                  <span className="product-name__sub-title">
                    {product.subName}
                  </span>
                </div>

                <div className="product-price">
                  <Price
                    previousPrice={product.previousPrice}
                    price={product.price}
                  />
                </div>
              </ProductSection>
            ))}
          </Slider>
        </Container>
      );
    } else {
      return products.map(product => (
        <ProductSection
          key={product.id}
          to={
            categoryPath && product.primaryRoute
              ? categoryPath.path + '/' + product.primaryRoute.slug
              : product.primaryRoute
              ? product.primaryRoute.path
              : '/'
          }
        >
          <div className="product-image">
            {product.images[0] && (
              <Image
                src={product.images[0].url}
                contain
                aspect="3:4"
                sizes={[1 / 5]}
                alt={product.images[0].alt}
              >
                {product.badges && product.badges.length > 0 && (
                  <BadgeWrapper>
                    <Badges badges={product.badges} />
                  </BadgeWrapper>
                )}
                <AddFavoriteButton product={product} />
              </Image>
            )}
          </div>

          <div className="product-name">
            <h4>{product.name}</h4>
            <span className="product-name__sub-title">{product.subName}</span>
          </div>

          <div className="product-price">
            <Price
              previousPrice={product.previousPrice}
              price={product.price}
            />
          </div>
        </ProductSection>
      ));
    }
  } else {
    return null;
  }
};

export default ProductList;
