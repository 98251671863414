import React, { Component } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { FavoriteListConsumer } from './FavoriteListProvider';

import { ReactComponent as Heart } from '../../svg/Heart.svg';

const IconContainer = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  bottom: 10px;
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
    fill: none;
    stroke: ${theme('colors.pink')};
  }

  &:hover svg {
    stroke: ${theme('colors.pinkDark')};
  }

  &.liked {
    svg {
      fill: ${theme('colors.pink')};
    }

    &:hover svg {
      fill: ${theme('colors.pinkDark')};
    }
  }
`;

class AddFavoriteItem extends Component {
  handleClick = event => {
    event.preventDefault();

    if (this.props.isInList(this.props.product.id)) {
      this.props.removeFromList(this.props.product.id);
    } else {
      this.props.addToList(this.props.product);
    }
  };

  render() {
    return (
      <IconContainer
        className={
          (this.props.isInList(this.props.product.id) && 'liked ') || ''
        }
      >
        <Heart onClick={this.handleClick} />
      </IconContainer>
    );
  }
}

const AddFavoriteButton = props => {
  const { product } = props;
  return (
    <FavoriteListConsumer>
      {({ favoriteList, addToList, isInList, removeFromList }) => (
        <AddFavoriteItem
          favoriteList={favoriteList}
          addToList={addToList}
          product={product}
          isInList={isInList}
          removeFromList={removeFromList}
        />
      )}
    </FavoriteListConsumer>
  );
};

export default AddFavoriteButton;
