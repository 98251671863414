import Analytics from '@jetshop/core/analytics/Analytics';
import { trackPage } from '@jetshop/core/analytics/tracking';
import startPageQuery from './StartPageQuery.gql';
import t from '@jetshop/intl';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import styled, { css } from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image';
import ProductList from '../Theme/ProductList';
import CategoryProducts from '../Theme/CategoryProducts';
import { ReactComponent as ArrowSVG } from '../../svg/Arrow.svg';
import LoadingPage from '../LoadingPage';
import Head from '@jetshop/core/components/Head';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BreakFlex = styled('div')`
  width: 100%;
`;

const FullwidthContainer = styled('div')`
  ${({ theme }) => theme.elements.fwContainer};
  background: ${({ theme }) => theme.colors.grey};
  padding-top: ${({ theme }) => parseInt(theme.sizes.gap) * 3}px;
  padding-bottom: ${({ theme }) => parseInt(theme.sizes.gap) * 3}px;
  ${({ theme }) => theme.below.sm} {
    padding-top: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px;
    padding-bottom: ${({ theme }) => parseInt(theme.sizes.gap) * 4}px;
  }
  &.ProductRow {
    margin-top: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px;
    padding-bottom: ${({ theme }) => parseInt(theme.sizes.gap)}px;
  }
`;

const Container = styled('div')`
  ${({ theme }) => theme.elements.container}
  h1, h2, h3 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  h2 {
    margin-bottom: 2.5rem;
    ${({ theme }) => theme.below.sm} {
      margin-bottom: 1.5rem;
    }
  }
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  ${({ theme }) => theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StartpageProductWrapper = styled('div')`
  ${({ theme }) => theme.elements.fwContainer}

  & > div[class*='StartpageProductWrapper__inner'] {
    ${({ theme }) => theme.elements.container}
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px -${({ theme }) =>
  theme.space[2]} 0 -${({ theme }) => theme.space[2]};
    margin: 0 auto;
    padding: 0;
    & > div {
    //  position: relative;
    //  overflow: hidden;
    //padding: 0 ${({ theme }) => parseInt(theme.sizes.gap) * 2}px;
    }
    ${({ theme }) => theme.below.md} {
      display: block;
      margin: 0 auto;
      //overflow: hidden;
      padding: 0 8px;
      & > a {
        display: inline-block;
        width: 50%;
        padding: 0 8px;
        margin: 0;
        margin-bottom: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px;
        float: left;
      }
    }
  }
  & > a {
    margin-bottom: 0;
  }
`;

// const StartPageTitle = ({ text }) => (
//   <MainSectionWrapper>
//     <Title>{text}</Title>
//   </MainSectionWrapper>
// );

// const startPageComponents = {
//   'Campaign Header': CampaignHeader,
//   'Category List': CategoryList,
//   Title: StartPageTitle,
//   Campaign: Campaign,
//   'Extra Details': ExtraDetails
// };

const ImagePuffCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 372px;
  text-align: center;
  position: relative;
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 372px;
  }
  @supports (-ms-accelerator: true) {
    height: 372px;
  }
  & > * {
    z-index: 2;
  }
  & > div:first-of-type {
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 !important;
    
    img {
      object-position: center top !important;
    }
  }
  
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top 0;
    left: 0;
    background: #000;
    opacity: .1;
    z-index: 1;
  }
`;

const UspCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 420px;
  text-align: center;
`;

const ArrowIcon = styled(ArrowSVG)`
  width: 8px;
  height: 13px;
  position: relative;
  top: 1px;
  margin-left: 1rem;
`;

const UspOuterBlock = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colorPrimary};
  padding: 25px;
  & > * {
    z-index: 2;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
  }
  &:before {
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    top: 24px;
    left: 24px;
    border: 6px solid ${({ theme }) => theme.colors.black};
  }
  &:after {
    width: calc(100% - 66px);
    height: calc(100% - 66px);
    top: 33px;
    left: 33px;
    border: 3px solid ${({ theme }) => theme.colors.black};
  }
  h2 {
    font-family: ${({ theme }) => theme.fontHeading};
    color: ${({ theme }) => theme.colors.black};
  }
`;

const UspInnerBlock = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  > div {
    padding: ${({ theme }) => parseInt(theme.sizes.gap) * 3}px;
  }
`;

const VertikalCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
  }
  @supports (-ms-accelerator: true) {
    width: 100%;
  }
`;

const PuffButtonFormat = css`
  padding: 0.5rem 2rem;
`;

// const PuffButtonWhite = styled(Link)`
//   ${PuffButtonFormat}
//   border: 2px solid #fff;
//   color: #fff;
//   position: absolute;
//   bottom: 2.5rem;
//   transition: all ease-in-out .2s;
//   &:hover {
//     background: #fff;
//     color: ${({ theme }) => theme.colors.black};
//   }
//   &.black {
//     color: ${({ theme }) => theme.colors.black};
//   }
//   &:after {
//     content: '\f908'
//     font-family: 'beyond-font';
//     font-size: 1rem;
//   }
// `;

const PuffButtonWhiteSpan = styled('span')`
  ${PuffButtonFormat}
  max-width: 140px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.5rem;
  width: 100%;
  margin: 0 auto;
  border: 2px solid #fff;
  color: #fff;
  transition: all ease-in-out .2s;
  &:hover {
    background: #fff;
    color: ${({ theme }) => theme.colors.black};
  }
  &.black {
    color: ${({ theme }) => theme.colors.black};
  }
  &:after {
    content: '\f908'
    font-family: 'beyond-font';
    font-size: 1rem;
  }
`;

const UspButton = styled(Link)`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 1rem;
`;

const VertikalOuter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.below.sm} {
    display: block;
  }

  div[role='img'] {
    ${({ theme }) => theme.below.md} {
      padding-top: 50% !important;
    }
  }
`;

const VertikalSection = styled('div')`
  flex: 1 1 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:last-of-type {
    text-align: left;
    background: ${({ theme }) => theme.colors.grey};
    padding: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px;
    ${({ theme }) => theme.below.sm} {
      padding: ${({ theme }) => theme.sizes.gap};
    }
    height: auto;
    & > div {
      width: 100%;
    }
  }
  & > div {
    height: 100% !important;
    padding: 0;
  }
  a {
    display: inline-block;
    margin-top: 1rem;
    //position: absolute;
    //bottom: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px;
    ${PuffButtonFormat}
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.below.sm} {
      position: relative;
      bottom: 0;
      margin-top: 10px;
    }
  }
  ${({ theme }) => theme.below.sm} {
    height: 100%;
    display: block;
  }
  
  
`;

const FullwidthItem = styled('div')`
  height: 430px;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  //background: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.below.sm} {
    height: auto;
  }
  & > div[class*="ImageElement"] {
    height: 100%;
  }
`;

const FullwidthItemContent = styled('div')`
  padding: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px
    ${({ theme }) => parseInt(theme.sizes.gap) * 4}px;
  ${({ theme }) => theme.below.sm} {
    padding: ${({ theme }) => theme.sizes.gap};
  }
  h2 {
    padding: 0;
    font-family: ${({ theme }) => theme.fontHeading};
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.below.sm} {
      margin-bottom: 0.5rem;
    }
  }
  a {
    display: inline-block;
    margin-top: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: 500;
  }
`;

const CatLink = styled(Link)`
  position: absolute;
  right: 17px;
  top: ${({ theme }) => theme.sizes.gap};
  ${({ theme }) => theme.below.sm} {
    top: auto;
    bottom: -50px;
  }
`;

const StartpagePuff = styled('div')`
  flex: 1 1 100%;
  padding: 0 ${({ theme }) => theme.sizes.gap};
  ${({ theme }) => theme.below.sm} {
    padding: 0;
  }
  ${({ theme }) => theme.below.sm} {
    & > div[class*='FullwidthPuffInner'] {
      display: block;
      & > div[class*='FullwidthItem'] {
        height: auto;
        h2 {
          padding: 0;
        }
      }
    }
  }
`;

const StartpagePuffLink = styled(Link)`
  flex: 1 1 100%;
  padding: 0 ${({ theme }) => theme.sizes.gap};
  ${({ theme }) => theme.below.sm} {
    padding: 0;
  }
  ${({ theme }) => theme.below.sm} {
    display: block;
    & > div[class*='FullwidthPuffInner'] {
      display: block;
      & > div[class*='FullwidthItem'] {
        height: auto;
        h2 {
          padding: 0;
        }
      }
    }
  }
`;

const FullwidthPuff = styled('div')`
  flex: 1 1 100%;
  padding: 0 ${({ theme }) => theme.sizes.gap};
  overflow: hidden;
  ${({ theme }) => theme.below.sm} {
    padding: 0;
  }
  ${({ theme }) => theme.below.sm} {
    & > div[class*='FullwidthPuffInner'] {
      display: block;
      & > div[class*='FullwidthItem'] {
        height: auto;
        h2 {
          padding: 0;
        }
      }
    }
  }
`;

const FullwidthPuffInner = styled('div')`
  display: flex;
  position: relative;
  ${({ theme }) => theme.below.sm} {
    min-height: 340px;
    overflow: hidden;
    margin-bottom: ${({ theme }) => theme.sizes.gap};
  }
  & > div[class*='ImageContainer'] {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  h2 {
    font-family: ${({ theme }) => theme.fontHeading};
    color: ${({ theme }) => theme.colors.white};
    padding: 0 ${({ theme }) => theme.sizes.gap};
    ${({ theme }) => theme.below.sm} {
      font-size: 2rem;
    }
  }
`;

const StartpagePuffInner = styled('div')`
  position: relative;
  ${({ theme }) => theme.below.sm} {
    min-height: 340px;
    overflow: hidden;
    margin-bottom: ${({ theme }) => theme.sizes.gap};
  }
  & > div[class*='ImageContainer'] {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  h2 {
    font-family: ${({ theme }) => theme.fontHeading};
    color: ${({ theme }) => theme.colors.white};
    padding: 0 ${({ theme }) => theme.sizes.gap};
    ${({ theme }) => theme.below.sm} {
      font-size: 2rem;
    }
  }
`;

const StartpageItemRow = styled('div')`
  display: flex;
  margin: ${({ theme }) => parseInt(theme.sizes.gap) * 2}px
    ${({ theme }) => -parseInt(theme.sizes.gap)}px 0
    ${({ theme }) => -parseInt(theme.sizes.gap)}px;
  ${({ theme }) => theme.below.sm} {
    display: block;
    margin: 0;
  }
  &:first-of-type {
    margin-top: 0;
  }
  &.fullbredd ~ .fullbredd {
    margin-top: 0;
  }
  &.odd {
    & > div[class*='FullwidthPuff'] {
      & > div[class*='FullwidthPuffInner'] {
        & > div:first-of-type {
          order: 2;
        }
        & > div:last-of-type {
          order: 1;
        }
      }
    }
  }
`;

const StartpageItem = item => {
  if (item.item) {
    const startPageItem = item.item.item;
    const type = startPageItem.name.toLowerCase();
    //<PuffButtonWhite to={startPageItem.linkUrl}>

    if (type.includes('puff')) {
      return (
        startPageItem.linkUrl && (
          <StartpagePuffLink
            to={startPageItem.linkUrl}
            className={'StartpagePuff'}
          >
            <StartpagePuffInner className={ImagePuffCSS}>
              {startPageItem.image && (
                <Image
                  src={startPageItem.image.url}
                  fillAvailableSpace={true}
                  aspect="1:1"
                  gravity={'north'}
                />
              )}
              <div
                dangerouslySetInnerHTML={{ __html: startPageItem.content }}
              />
              {startPageItem.linkUrl && (
                <PuffButtonWhiteSpan>{t('Shopping')}</PuffButtonWhiteSpan>
              )}
            </StartpagePuffInner>
          </StartpagePuffLink>
        )
      );
    }

    if (type.includes('usp')) {
      return (
        <StartpagePuff>
          <StartpagePuffInner className={UspCSS}>
            <UspOuterBlock>
              <UspInnerBlock>
                <div
                  dangerouslySetInnerHTML={{ __html: startPageItem.content }}
                />
                {startPageItem.linkUrl && (
                  <UspButton to={startPageItem.linkUrl}>
                    {t('Shop now')}
                    <ArrowIcon />
                  </UspButton>
                )}
              </UspInnerBlock>
            </UspOuterBlock>
          </StartpagePuffInner>
        </StartpagePuff>
      );
    }

    // style={
    //   startPageItem.image && {
    //     backgroundImage: `url(${startPageItem.image.url})`
    //   }
    // }

    if (type.includes('vertikal')) {
      return (
        <StartpagePuff className={'StartpagePuff'}>
          <StartpagePuffInner className={VertikalCSS}>
            <VertikalOuter>
              <VertikalSection>
                {startPageItem.image && (
                  <Image
                    src={startPageItem.image.url}
                    cover
                    aspect="3:1"
                    gravity="north"
                  />
                )}
              </VertikalSection>

              <VertikalSection>
                <div
                  dangerouslySetInnerHTML={{ __html: startPageItem.content }}
                />
                {startPageItem.linkUrl && (
                  <UspButton to={startPageItem.linkUrl}>
                    {t('Shopping')}
                  </UspButton>
                )}
              </VertikalSection>
            </VertikalOuter>
          </StartpagePuffInner>
        </StartpagePuff>
      );
    }

    if (type.includes('fullbredd')) {
      return (
        <FullwidthPuff className={'FullwidthPuff'}>
          <FullwidthPuffInner className={'FullwidthPuffInner'}>
            <FullwidthItem className={'FullwidthItem'}>
              {startPageItem.image && (
                <Image src={startPageItem.image.url} cover aspect="24:18" />
              )}
            </FullwidthItem>
            <FullwidthItem>
              <FullwidthItemContent>
                <div
                  dangerouslySetInnerHTML={{ __html: startPageItem.content }}
                />
                {startPageItem.linkUrl && (
                  <UspButton to={startPageItem.linkUrl}>
                    {t('Shopping')}
                  </UspButton>
                )}
              </FullwidthItemContent>
            </FullwidthItem>
          </FullwidthPuffInner>
        </FullwidthPuff>
      );
    }

    if (type.includes('kategori')) {
      const catID = parseInt(startPageItem.name.replace(/\D/g, ''));
      const catName = startPageItem.content
        .replace(/<\/?[^>]+(>|$)/g, '')
        .toLowerCase();
      return (
        <BreakFlex>
          <FullwidthContainer>
            <Container>
              {startPageItem.linkUrl && (
                <CatLink to={startPageItem.linkUrl}>
                  Visa alla {catName} <ArrowIcon />
                </CatLink>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: startPageItem.content }}
              />
              <StartpageProductWrapper>
                <div className="StartpageProductWrapper__inner">
                  <CategoryProducts id={catID} slick={true} />
                </div>
              </StartpageProductWrapper>
            </Container>
          </FullwidthContainer>
        </BreakFlex>
      );
    }

    return '';
  }
};

const RowItems = items => {
  if (items) {
    return items.items.map(item => <StartpageItem key={item.id} item={item} />);
  }
};

const StartpageItems = result => {
  let rows = [];

  if (result.items.data.startPage) {
    for (const row in result.items.data.startPage.items) {
      const item = result.items.data.startPage.items[row];
      if (!rows[item.row]) rows[item.row] = [];
      rows[item.row][item.column - 1] = item;

      //if ( item.item.__typename === 'CustomItem' ) {
      rows[item.row][item.column] =
        item.item.__typename === 'CustomItem' ? 'CustomItem' : 'ProductItem';
      // } else {
      //   rows[item.row][item.column] = 'ProductItem';
      // }
    }

    return rows.map((rows, i) => {
      let rowType = rows.pop();

      const classType = rows[0]
        ? rows[0].item.name.replace(/\d+/g, '').toLowerCase()
        : '';
      if (rowType === 'CustomItem') {
        return (
          <StartpageItemRow
            key={i}
            className={`${classType} ${i % 2 ? '' : `odd`} `}
          >
            <RowItems items={rows} />
          </StartpageItemRow>
        );
      } else {
        let products = rows.map(item => item.item);
        return (
          <FullwidthContainer key={i} className={'ProductRow'}>
            <StartpageProductWrapper key={i}>
              <div className="StartpageProductWrapper__inner">
                <ProductList key={i} products={products} />
              </div>
            </StartpageProductWrapper>
          </FullwidthContainer>
        );
      }
    });
  }

  // return (result.items.data.startPage) ?
  //
  //   result.items.data.startPage.items.map( (item) => (
  //
  //     <StartpageItemRow key={item.id}>
  //
  //     </StartpageItemRow>
  //   ))
  //
  // : '';
  return '';
};

const StartPage = ({ location: { pathname }, startPageId }) => {
  return (
    <Fragment>
      <Analytics
        track={trackPage({
          title: 'Trofé',
          pathname
        })}
      />
      <StartPageWrapper>
        <Query
          variables={startPageId && { startPageId: startPageId }}
          query={startPageQuery}
        >
          {result => {
            const { loading, data } = result;

            if (loading) return <LoadingPage />;
            if (!data) return null;
            return (
              <>
                {result.data.startPage && result.data.startPage.head && (
                  <Head data={result.data.startPage.head} />
                )}
                <MainSectionWrapper>
                  <StartpageItems items={result} />
                </MainSectionWrapper>
                {/*
                <StartPageRenderer
                  result={result}
                  startPageComponents={startPageComponents}
                  ProductGrid={WrappedProductGrid}
                  LoadingPage={LoadingPage}
                />
                */}
              </>
            );
          }}
        </Query>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
